/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import { Link } from "../components/nav"
import { Fragment, useState, useEffect } from "react"
import { ViewElement, IconButton } from "./atoms"
import { BurgerIcon, CrossIcon } from "./graphics"
import { useStaticQuery, graphql, navigate } from "gatsby"
import { useTranslation, Trans } from "react-i18next"
import { useLanguageSelector } from "./locale-selector"
import { CaretUp, CaretDown } from "../images/menu"
import { NavSearch } from "./search"
import { Connection, ContentNode } from "../data/content"
import { useLocalisedContentArray } from "./featured"
import _ from "lodash"

type direction = "left" | "right"

interface LinkItem {
  label: string
  link?: string | (() => any)
  description?: string
  children?: LinkItem[]
  dir?: direction
}

const supportLinks = [
  {
    label: "Support",
    link: "/support",
  },
]

const singleLinks = [
  {
    label: "Workshop",
    link: "https://workshop.progressive.international",
  },
  {
    label: "Get Involved",
    link: "/join",
  },
]

const flexRow = {
  display: "flex",
  flexDirection: "row",
}

const barStyles = {
  bg: "black",
  color: "white",
  ...flexRow,
  alignItems: ["start", "start", "center"],
  py: 2,
  lineHeight: "1em",
  verticalAlign: "center",
}

const topBarPositionStyle = {
  position: "fixed",
  paddingTop: [2, 3, 4],
  width: "100%",
  top: 0,
  left: 0,
  zIndex: 999,
  fontSize: 4,
  height: ["100%", null, "auto"],
  pointerEvents: "none",
}

const topBarChildManagerStyle = {
  // Consecutive cells of stuff in a row
  ...flexRow,
  maxHeight: ["100%", null, "none"],
  height: ["100%", null, "none"],
  flexWrap: ["wrap", "wrap", "nowrap"],
  // All in one bar, as high as each other
  alignItems: "stretch",
  alignContent: "stretch",
  pointerEvents: "none",
}

const topBarMainContentManagerStyle = {
  ...flexRow,
  flexDirection: ["column", "column", "row"],
  maxHeight: ["100%", "100%", "35px"],
  p: 0,
  width: "100%",
  maxWidth: theme => theme.sizes.container,
  flexGrow: 1,
  flexShrink: 0,
  flexWrap: "nowrap",
  pointerEvents: "none",
}

const topBarPersistentContentStyle = {
  ...flexRow,
  flexWrap: "nowrap",
  flexShrink: [1, 1, 1],
  width: ["100%", "100%", "50%"],
  overflow: "visible",
  zIndex: 100,
  position: "relative",
  alignItems: "stretch",
  alignContent: "stretch",
  pointerEvents: "auto",
}

const wordmarkContentStyle = {
  ...barStyles,
  px: 2,
  width: "100%",
  flexGrow: 1,
  flexShrink: 1,
  pointerEvents: "auto",
  alignSelf: "center",
  height: "35px",
  fontSize: ["min(4.5vw, 18px)", "inherit", "min(1.85vw, 18px)"],
  a: {
    placeSelf: "center",
  },
}

const mobileMenuPositionStyle = {
  justifySelf: "end",
  display: ["flex", "flex", "none"],
  ml: "auto",
  alignItems: "stretch",
  alignContent: "stretch",
  flexShrink: 0,
  flexGrow: 0,
}

const mobileMenuContainerStyle = {
  ...barStyles,
  alignItems: "center",
  mx: 2,
  px: 2,
}

const linksContainerStyle = {
  flexShrink: 1,
  flexGrow: [0, 0, 1],
  width: ["100%", "100%", "100%"],
  flexDirection: "row",
  flexWrap: ["wrap", "wrap", "nowrap"],
  mt: [2, 2, 0],
  bg: ["black", "black", "transparent"],
  pointerEvents: "auto",
  borderBottom: ["1px solid white", "1px solid white", "none"],
}

const mainLinksContainerStyle = {
  ...barStyles,
  py: [0, 0, null],
  px: [0, 0, 2],
  paddingRight: "0px !important",
  display: "flex",
  flexGrow: 1,
  flexShrink: 1,
  flexDirection: ["column", "column", "row"],
  flexWrap: "nowrap",
  justifyContent: ["flex-start", "flex-start", "flex-end"],
}

const singleLinksContainerStyle = {
  ...barStyles,
  py: [0, 0, null],
  px: [0, 0, 2],
  paddingLeft: "0px !important",
  display: "flex",
  flexGrow: 1,
  flexShrink: 1,
  flexDirection: ["column", "column", "row"],
  flexWrap: "nowrap",
  justifyContent: ["flex-start", "flex-start", "flex-end"],
  "span::after": {
    display: "none !important",
  },
}

const specialLinksContainerStyle = {
  display: "flex",
  alignItems: "center",
  flexGrow: 0,
  flexShrink: 0,
  mx: [0, 0, 2],
  bg: "accent",
  borderRadius: [0, 0, 100],
  color: "white",
  "span::after": {
    display: "none",
  },
  borderBottom: ["1px solid white", "1px solid white", "none"],
}

const mobileOnly = {
  display: ["block", "block", "none"],
}

const overhangingBarStyle = {
  ...barStyles,
  flexGrow: 1,
  flexShrink: 1,
  display: ["none", "none", "block"],
}

const searchStyle = {
  maxWidth: ["none", "400px", "400px"],
  width: ["100%", "auto", "auto"],
  maxHeight: "40px",
  height: "100%",
  float: ["right", "right", "none"],
  marginTop: ["7.5px", "7.5px", "0px"],
  marginBottom: ["17.5px", "17.5px", "0px"],
  backgroundColor: "#280000",
  borderRadius: ["50px", "50px", "0px"],
  "> div": {
    height: "100%",
    padding: "5px",
  },
  "> div > form": {
    height: "100%",
    borderRadius: "50px",
  },
  "> div > form > div": {
    height: "100%",
  },
  "> div > form > div > div > input": {
    minWidth: ["250px", "300px", "50px"],
    width: ["auto", "auto", "7vw"],
    padding: "0px 10px",
    paddingRight: "0px",
    height: "100%",
    border: "none",
    fontSize: "0.9em",
  },
}

export const HEADER_ID = "site-header"

export const PageHeader: ViewElement<{ path: string }> = ({ path }) => {
  const { header, pages } = useStaticQuery(graphql`
    query HeaderLinks {
      header: menusJson(title: { eq: "header" }) {
        items {
          label
          children {
            label
            link
            description
          }
        }
      }
      pages: allMarkdownRemark(
        filter: { fields: { type: { eq: "misc-pages" } } }
      ) {
        edges {
          node {
            id
            fields {
              path
              translations {
                childMarkdownRemark {
                  frontmatter {
                    locale
                  }
                }
              }
            }
            frontmatter {
              title
            }
          }
        }
      }
    }
  `) as { header: { items: LinkItem[] }; pages: Connection<ContentNode> }

  const [visibleOnMobile, setVisibleOnMobile] = useState(false)
  const [localisedPages, setLocalisedPages] = useState<
    ContentNode[] | undefined
  >(undefined)
  const [localisedHeaders, setLocalisedHeaders] = useState(header)

  const localisedPagesRaw = useLocalisedContentArray(
    pages?.edges.map(edge => edge.node)
  )

  function localiseLinkItem(linkItem: LinkItem): LinkItem {
    let result = _.clone(linkItem)
    if (result.children && result.children.length > 0) {
      result.children = result.children.map(linkItemX =>
        localiseLinkItem(linkItemX)
      )
    } else {
      localisedPages?.forEach(page => {
        if (
          result &&
          typeof result.link === "string" &&
          result.link?.replace(/[^a-zA-Z]/g, "") ==
            page.fields.path.replace(/[^a-zA-Z]/g, "")
        ) {
          result.link = result.link + "/" + page.locale
        }
      })
    }

    return result
  }

  useEffect(() => {
    console.log("localising...")
    if (!_.isEqual(localisedPagesRaw, localisedPages)) {
      setLocalisedPages(localisedPagesRaw)
    }
  }, [localisedPagesRaw])

  useEffect(() => {
    console.log("change...")
    const result = _.clone(header)
    result.items = result.items.map(linkItem => {
      return localiseLinkItem(linkItem)
    })
    setLocalisedHeaders(result)
  }, [header, localisedPages])

  useEffect(() => {
    setVisibleOnMobile(false)
  }, [path])

  const locale = useLanguageSelector()

  return (
    <div key="site-header" sx={topBarPositionStyle}>
      <div id="site-header-items" sx={topBarChildManagerStyle}>
        {/* Invisible bit on the left */}
        <div id="desktop-menu-gutter" sx={{ flexGrow: 1, flexShrink: 1 }} />
        {/* Content in the middlle */}
        <div id="menu-bar" sx={topBarMainContentManagerStyle}>
          <div
            id="persistent-header-content"
            sx={topBarPersistentContentStyle}
            id={HEADER_ID}
          >
            <div sx={{ ml: 2 }} />
            <Logo />
            <div sx={{ mr: 2 }} />
            <div id="wordmark" sx={wordmarkContentStyle}>
              <WordMark />
            </div>
            <div sx={mobileMenuPositionStyle}>
              <div id="mobile-menu-button" sx={mobileMenuContainerStyle}>
                <MobileMenuButton
                  isOpen={visibleOnMobile}
                  onClick={() => setVisibleOnMobile(o => !o)}
                />
              </div>
            </div>
          </div>
          {/* This part will fold into a hamburger on mobile */}
          <div
            id="links"
            sx={{
              ...linksContainerStyle,
              overflowY: "visible",
              width: "auto",
              whiteSpace: "nowrap",
              display: [
                visibleOnMobile ? "block" : "none",
                visibleOnMobile ? "block" : "none",
                "flex",
              ],
            }}
          >
            <div id="main-links" sx={mainLinksContainerStyle}>
              <HeaderLinks items={[...localisedHeaders.items]} />
            </div>
            <div id="single-links" sx={singleLinksContainerStyle}>
              <HeaderLinks
                items={[
                  ...singleLinks,
                  {
                    dir: "right",
                    label: locale.currentLanguageCode.code,
                    children: locale.languages.map(l => ({
                      label: l.name as string,
                      link: () => locale.changeLanguage(l.code),
                    })),
                  },
                ]}
              />
            </div>
            <div id="special-links" sx={specialLinksContainerStyle}>
              <HeaderLinks items={supportLinks} />
            </div>
            <div id="search-bar" sx={searchStyle}>
              <NavSearch />
            </div>
            <div id="mobile-menu-footer" sx={mobileOnly}>
              <MobileMenuFooter />
            </div>
          </div>
        </div>
        {/* Visible spill-over on the right */}
        <div id="desktop-menu-decoration" sx={overhangingBarStyle} />
      </div>
    </div>
  )
}

export const Logo: React.FC<any> = () => {
  return (
    <img
      src={require("../images/circle.svg")}
      sx={{
        width: "100%",
        flexShrink: 10,
        cursor: "pointer",
        pointerEvents: "auto",
      }}
      onClick={() => {
        navigate("/")
      }}
    />
  )
}

export const WordMark: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Link to="/" sx={{ textTransform: "uppercase", fontWeight: 600 }}>
      {t("Progressive International")
        .split(" ")
        .map(word => (
          <span key={word} sx={{ display: "inline-block", mr: 1 }}>
            {word}
          </span>
        ))}
    </Link>
  )
}

const MobileMenuButton: React.FC<{ isOpen?: boolean; onClick: any }> = ({
  isOpen,
  onClick,
}) => {
  return (
    <IconButton
      onClick={onClick}
      sx={{
        display: "block",
        m: 0,
        p: 0,
        lineHeight: "1em",
      }}
    >
      {!isOpen ? (
        <BurgerIcon width={18} height={18} />
      ) : (
        <CrossIcon width={18} height={18} />
      )}
    </IconButton>
  )
}

const rowStyle = {
  display: "block",
  width: ["100%", "100%", "auto"],
  position: "relative",
}

const linkStyle = {
  ...rowStyle,
  textTransform: "uppercase",
  fontWeight: 500,
  px: [3, 3, 2],
  py: [3, 3, 0],
}

const sectionStyle = {
  ...rowStyle,
  borderTop: ["1px solid white", "1px solid white", "none"],
}

const topLevelLinkStyle = {
  ...linkStyle,
  ...sectionStyle,
  fontWeight: 600,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  "> span": {
    position: "relative",
    display: "inline-block",
    py: "3px",
  },
  "> span::after": {
    content: '""',
    display: "inline-block",
    position: "absolute",
    bottom: "0",
    left: "0",
    width: "100%",
    borderBottomWidth: "2px",
    borderBottomStyle: "solid",
    borderBottomColor: "accent",
  },
}

const sectionTitleStyle = {
  ...linkStyle,
  cursor: "default",
  fontWeight: 600,
  opacity: [0.66, 0.66, 1],
}

const descriptionStyle = {
  fontFamily: "monospace",
  fontSize: 13,
  fontWeight: 300,
  whiteSpace: "normal",
  lineHeight: 1.3,
}

const dropdownStyle = (dir: direction = "left") => ({
  width: ["100%", "100%", 250],
  position: ["relative", "relative", "absolute"],
  top: "100%",
  py: ["1px", "1px", 2],
  px: [0, 0, 2],
  [dir]: [0, 0, -2],
  bg: ["transparent", "transparent", "black"],
  overflow: "auto",
  "max-height": ["unset", "unset", "calc(100vh - 70px)"],
})

const subMenuLinkStyle = {
  ...linkStyle,
  fontWeight: 600,
  py: 0,
  my: 3,
}

const HeaderLinks: React.FC<{ items: LinkItem[] }> = ({ items }) => {
  return (
    <Fragment>
      {items.map((item, i) => {
        return <HeaderGroup key={i} item={item} />
      })}
    </Fragment>
  )
}

const HeaderGroup: React.FC<{ item: LinkItem }> = ({ item }) => {
  const [mobileExpanded, setMobileExpanded] = useState(false)

  return item.link && typeof item.link === "string" ? (
    <Link to={item.link} sx={topLevelLinkStyle}>
      <span>
        <Trans>{item.label}</Trans>
      </span>
    </Link>
  ) : item.children ? (
    <div sx={sectionStyle}>
      <div
        sx={{
          ...sectionTitleStyle,
          ...(mobileExpanded ? { pb: [0, 0, 0] } : {}),
        }}
        onClick={() => {
          setMobileExpanded(o => !o)
        }}
      >
        <Trans>{item.label}</Trans>
        <CaretDown
          sx={{
            display: ["none", "none", "inline-block"],
            verticalAlign: "middle",
            marginLeft: "3px",
            marginTop: "-1px",
          }}
        />
        <Box sx={{ display: ["block", "block", "none"], float: "right" }}>
          {mobileExpanded ? (
            <CaretUp sx={{ fill: "white" }} />
          ) : (
            <CaretDown sx={{ fill: "white" }} />
          )}
        </Box>
      </div>
      <div
        sx={{
          ...dropdownStyle(item.dir),
          display: [mobileExpanded ? "block" : "none", null, "none"],
          ":hover + &, *:hover > &, &:hover": {
            display: [
              mobileExpanded ? "block" : "none",
              null,
              "block !important",
            ],
          },
        }}
      >
        {item.children.map((item, i) =>
          typeof item.link === "string" ? (
            <Link key={i} to={item.link || "/"} sx={subMenuLinkStyle}>
              <Trans>{item.label}</Trans>
              {!!item.description && (
                <div sx={descriptionStyle}>
                  <Trans>{item.description}</Trans>
                </div>
              )}
            </Link>
          ) : (
            <div
              key={i}
              onClick={item.link}
              sx={{
                ...subMenuLinkStyle,
                cursor: item.link && "pointer",
              }}
            >
              <Trans>{item.label}</Trans>
              {!!item.description && (
                <div sx={descriptionStyle}>
                  <Trans>{item.description}</Trans>
                </div>
              )}
            </div>
          )
        )}
      </div>
    </div>
  ) : null
}

const MobileMenuFooter: React.FC = () => {
  return (
    <Fragment>
      {/* <Box>Facebook</Box>
      <Box>Twitter</Box>
      <Box>Instagram</Box> */}
    </Fragment>
  )
}
