/** @jsx jsx */

import { jsx, SxStyleProp } from "theme-ui"
import {
  FC,
  HTMLAttributes,
  ButtonHTMLAttributes,
  ReactElement,
} from "react"
import GatsbyImage from "gatsby-image/withIEPolyfill"
import { ContentNode, SharpImage } from "../data/content"
import { ContentBlock } from "./page"
import { Link, LinkProps } from './nav';
import theme from '../gatsby-plugin-theme-ui/index';
import { Trans } from "react-i18next"
import { Localised } from "./featured"

const gradients: Record<string, any> = {
  blueprintHex: {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(199.85deg, ${theme.colors.blueprintHex} 0.13%, rgba(255, 255, 255, 0) 82.82%)`,
    '.hover-parent:hover &': {
      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(199.85deg, ${theme.colors.blueprintHex} 0.13%, ${theme.colors.blueprintHex} 82.82%)`,
    }
  },
  movementHex: {
    background: `linear-gradient(183.39deg, ${theme.colors.movementHex} -18.04%, rgba(255, 255, 255, 0) 96.03%)`,
    '.hover-parent:hover &': {
      background: `linear-gradient(183.39deg, ${theme.colors.movementHex} -18.04%, ${theme.colors.movementHex} 96.03%)`,
    }
  },
  wireHex: {
    background: `linear-gradient(210deg, ${theme.colors.wireHex} 5%,rgba(63,39,255,0) 70%)`,
    '.hover-parent:hover &': {
      background: `linear-gradient(180deg, ${theme.colors.wireHex} 23.47%, ${theme.colors.wireHex} 80%)`,
    }
  },
  observatoryHex: {
    background: `linear-gradient(210deg, ${theme.colors.observatoryHex} 5%,rgba(63,39,255,0) 70%)`,
    '.hover-parent:hover &': {
      background: `linear-gradient(180deg, ${theme.colors.observatoryHex} 23.47%, ${theme.colors.observatoryHex} 80%)`,
    }
  },
  latestHex: {
    background: `linear-gradient(183.39deg, ${theme.colors.latestHex} -18.04%, rgba(255, 255, 255, 0) 96.03%)`,
    '.hover-parent:hover &': {
      background: `linear-gradient(183.39deg, ${theme.colors.latestHex} -18.04%, ${theme.colors.latestHex} 96.03%)`,
    }
  },
}

export type ViewElement<Props = {}, Attrs = HTMLAttributes<{}>> = FC<
  Props & Omit<Attrs, keyof Props>
>

export const IconButton: ViewElement<ButtonHTMLAttributes<{}>> = props => (
  <button
    sx={{
      border: "none",
      outline: "none",
      bg: "rgba(0,0,0,0)",
      cursor: "pointer",
      color: "inherit",
    }}
    {...props}
  />
)

export const BulletPoint: ViewElement<{
  imgSrc: string
  content: string
}> = ({ imgSrc, content }) => {
  return (
    <div sx={{ display: "flex", marginTop: "15px", marginBottom: "15px" }}>
      <img
        src={imgSrc}
        sx={{
          filter: "brightness(0) saturate(100%) invert(19%) sepia(88%) saturate(4986%) hue-rotate(338deg) brightness(103%) contrast(98%)",
          height: "55px",
          width: "55px",
          margin: "12.5px",
          marginLeft: "0px"
        }}
      />
      <div sx={{
        alignSelf: "center",
        fontWeight: "500"
      }}>
        <Trans>
          {content}
        </Trans>
      </div>
    </div>
  )
}

export const QABox: ViewElement<{
  question: string
}> = ({ question, ...props }) => {

  return (
    <div>
      <p sx={{ fontWeight: "600", marginBottom: "2.5px" }}>
        {question}
      </p>
      <p sx={{ marginTop: "2.5px", lineHeight: "20px" }} {...props}>
      </p>
    </div>
  )
}

export const ImageCard: ViewElement<{
  src?: string | SharpImage
  overlay?: string
  gradient?: string
  position?: string
}> = ({ src, overlay, children, gradient, position = 'center', ...props }) => {
  return (
    <div
      sx={{
        position: "relative",
      }}
      {...props}
    >
      {typeof src === "string" ? (
        <div
          sx={{
            backgroundImage: "url('" + src + "')",
            zIndex: 0,
            backgroundSize: "cover",
            backgroundPosition: position,
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%"
          }}
        />
      ) : (
        <div
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: 0,
            left: 0,
            top: 0,
          }}
        >
          {src && (
            <GatsbyImage
              objectFit="cover"
              objectPosition={position}
              sx={{
                width: "100%",
                height: "100%",
                maxWidth: "100% !important"
              }}
              {...src}
            />
          )}
        </div>
      )}
      {(overlay || gradient) && (
        <div
          sx={{
            position: "absolute",
            zIndex: 1,
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            ...(gradient
              ? gradients[gradient]
              : { background: (theme.colors as any)[overlay!] ?? overlay }
            ),
            mixBlendMode: "multiply",
          }}
        />
      )}
      <div
        sx={{
          position: "relative",
          zIndex: 3,
          left: 0,
          top: 0,
          boxSizing: "border-box",
        }}
      >
        {children}
      </div>
    </div>
  )
}

export const DymaxionCard: ViewElement<{ overlay?: string }> = props => (
  <ImageCard src={require("../images/dymaxion-dash.png")} {...props} />
)

export const Rows: ViewElement<{ gap?: number | number[] }> = ({ gap, ...props }) => (
  <div sx={{ "> *:not(:last-child)": { mb: gap } }} {...props} />
)

export const ResponsiveSplit: ViewElement<{
  children: [ReactElement, ReactElement],
  isRtl: boolean
}> = ({ children: [first, second], isRtl }) => (
  <ContentBlock
    sx={{
      p: 0,
      px: [0, null, 4],
      display: ["block", null, "flex"],
      flexDirection: ["column", null, "row"],
    }}
  >
    <first.type
      sx={{
        ml: isRtl ? 5 : 0,
        mr: !isRtl ? 5 : 0,
        width: ["100%", null, "66%"],
      }}
      {...first.props}
    />
    <second.type sx={{ flex: 1 }} {...second.props} />
  </ContentBlock>
)

export const Hr: ViewElement<{ gap?: number }> = props => (
  <hr
    sx={{
      border: "none",
      height: [3, null, 6],
      backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='-1 -1 2 2'%3E%3Ccircle fill='red' r='.87' /%3E%3C/svg%3E")`,
      backgroundSize: ["6px 3px", null, "12px 6px"],
    }}
    {...props}
  />
)

export const Columns: ViewElement<{
  gap?: number
  mobileLayout?: "wrap" | "wrap-reverse"
  flexStyle?: SxStyleProp
}> = ({ gap = 0, flexStyle, mobileLayout, children, ...props }) => {
  if (mobileLayout) {
    return (
      <div {...props}>
        <div
          sx={{
            display: "flex",
            flexDirection: [
              mobileLayout === "wrap" ? "column" : "column-reverse",
              null,
              "row",
            ],
            "> *": { mr: [null, null, gap] },
            mr: [null, null, -gap],
            ...flexStyle,
          }}
        >
          {children}
        </div>
      </div>
    )
  }

  return (
    <div {...props}>
      <div
        sx={{
          display: "flex",
          flexDirection: "row",
          "> *": { mr: gap },
          mr: -gap,
          ...flexStyle,
        }}
      >
        {children}
      </div>
    </div>
  )
}

export const BodyText: ViewElement = props => (
  <div
    sx={{
      fontFamily: "prose",
      fontSize: [16, null, 19],
      lineHeight: "130%",
      letterSpacing: -0.1,
    }}
    {...props}
  />
)

export const ChipSet: ViewElement<{
  node: ContentNode
  dirFlexRow: any
  color?: string
}> = ({ node, dirFlexRow, color = "wireHex" }) => {

  const shortenedDate = node.frontmatter.publishDate?.split('T')[0]

  return (
    <Columns gap={1} sx={{ flexDirection: dirFlexRow, display: 'flex' }}>
      {node.frontmatter.region && (
        <Chip color={color}><Localised node={node.frontmatter.region}>{n => n?.title}</Localised></Chip>
      )}
      {node.frontmatter.topic && (
        <Chip color={color}><Localised node={node.frontmatter.topic}>{n => n?.title}</Localised></Chip>
      )}
      {shortenedDate && (
        <Chip color={color}>{shortenedDate}</Chip>
      )}
    </Columns>
  )
}

export const Chip: ViewElement<{
  color: string
}> = ({ color, ...props }) => (
  <div
    sx={{
      display: "inline-block",
      backgroundColor: color,
      p: "2px",
      marginRight: "3px",
      pb: 0,
      fontSize: 11,
      color: "white",
      textTransform: "uppercase",
    }}
    {...props}
  />
)

export const ChipLink: ViewElement<{ color: string } & LinkProps, React.AnchorHTMLAttributes<{}>> = ({ color, to, ...props }) => (
  <Link
    sx={{
      display: "inline-block",
      backgroundColor: color,
      p: 1,
      pb: 0,
      fontSize: 11,
      color: "white",
      textTransform: "uppercase",
    }}
    to={to}
    {...props}
  />
)
