/** @jsx jsx */
import { jsx, Flex, Box } from "theme-ui"
import { Grid } from "theme-ui"
import { Link } from "../components/nav"
import { Fragment, useEffect, useState } from "react"
import { Hr, Columns, Rows, ImageCard } from "./atoms"
import { useStaticQuery, graphql, navigate } from "gatsby"
import { useTranslation, Trans } from "react-i18next"
import { ContentBlock } from "./page"
import { useTextDirection } from "../i18n"
import { ActionNetworkFooterForm } from "./an-footer-form"
import { BriefingDynamicPopup } from "./popups"

const FOOTER_QUERY = graphql`
  query FooterImageQuery {
    footer: menusJson(title: { eq: "footer" }) {
      items {
        label
        children {
          label
          link
        }
      }
    }
    image: file(relativePath: { eq: "dark_map.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`

export const PageFooterCommon: React.FC<{}> = ({}) => {
  const { footer, image } = useStaticQuery(FOOTER_QUERY)
  const [onIndex, setOnIndex] = useState(false)

  const {
    i18n: { language },
    t,
  } = useTranslation()
  const dir = useTextDirection(language)

  useEffect(() => {
    try {
      setOnIndex(!!document.getElementById("index-indicator"))
    } catch (e) {
      if (e instanceof ReferenceError) {
        console.log("Document is not defined yet.")
      }
    }
  })

  return (
    <Fragment>
      <Box
        className="page-footer-links"
        sx={{ marginBottom: onIndex ? "180px" : 0 }}
      >
        <ContentBlock sx={{ pt: 0, pb: [5, null, 6] }}>
          <Box sx={{ pt: [3, 4, 5] }} />
          <Hr sx={{ mt: 0, mb: [5, null, 6] }} />

          <Grid
            gap={[4, null, 4]}
            columns={[1, null, 2]}
            sx={{ variant: "headings.3", direction: dir.isRtl ? "rtl" : "ltr" }}
          >
            {footer.items.map((item: any, i) => (
              <Rows key={i} gap={[3, null, 4]}>
                <div sx={{ color: "red" }}>
                  <Trans>{item.label}</Trans>
                </div>
                <Flex
                  sx={{
                    flexDirection: "row",
                    flexWrap: ["wrap", null, "nowrap"],
                  }}
                >
                  {item.children.map((item: any, i) => (
                    <Link
                      key={i}
                      to={item.link}
                      sx={{
                        color: "inherit",
                        textDecoration: "none",
                        ml: dir.isRtl ? [3, 4, 5] : [],
                        mr: !dir.isRtl ? [3, 4, 5] : [],
                        borderBottom: "2px solid red",
                        borderBottomColor: "red",
                        fontWeight: 600,
                        ":hover": {
                          color: "red",
                        },
                      }}
                    >
                      <Trans>{item.label}</Trans>
                    </Link>
                  ))}
                </Flex>
              </Rows>
            ))}
          </Grid>
        </ContentBlock>
      </Box>

      <div
        id="briefing-footer"
        sx={{
          bg: "red",
          direction: dir.isRtl ? "rtl" : "ltr",
          position: onIndex ? "sticky" : "relative",
          bottom: "-1px",
          zIndex: "3",
        }}
      >
        {!onIndex && (
          <ContentBlock>
            <Grid columns={[1, 2, 2]}>
              <div sx={{ variant: "headings.2", maxWidth: "20ch" }}>
                <Trans>Receive the Progressive International briefing</Trans>
              </div>
              <ActionNetworkFooterForm />
            </Grid>
          </ContentBlock>
        )}
        {onIndex && <BriefingDynamicPopup />}
      </div>

      <ImageCard src={image?.childImageSharp}>
        <ContentBlock
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "end",
            height: 300,
          }}
        >
          <Link
            to="/"
            sx={{
              display: "flex",
              height: [`3em`, null, `5em`],
            }}
          >
            <img
              src={require("../images/circle.svg")}
              sx={{
                size: [`3em`, null, `5em`],
                flexShrink: 0,
                flexGrow: 0,
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/")
              }}
            />
            <Box
              sx={{
                ml: 2,
                bg: "black",
                color: "white",
                p: 2,
                width: "100%",
                flexGrow: 1,
                flexShrink: 1,
                minWidth: "auto",
                lineHeight: "0.9em",
                fontSize: [`1.5em`, null, `2.5em`],
                display: "flex",
                alignItems: "center",
                maxWidth: "max-content",
              }}
            >
              <Link to="/" sx={{ textTransform: "uppercase", fontWeight: 600 }}>
                {t("Progressive International")
                  .split(" ")
                  .map(word => (
                    <Box key={word}>{word}</Box>
                  ))}
              </Link>
            </Box>
          </Link>
        </ContentBlock>
      </ImageCard>

      <div
        sx={{
          bg: "black",
          fontFamily: "monospace",
          fontWeight: 300,
          fontSize: [13, null, 15],
          color: "white",
          textTransform: "uppercase",
        }}
      >
        <ContentBlock
          sx={{ py: [3] }}
          sx={{
            "& a:hover": {
              opacity: "0.7 !important",
            },
          }}
        >
          <Grid columns={[1, null, 2]} gap={[2, null, 4]}>
            <Columns gap={4}>
              <Link to="/privacy-policy">
                <Trans>Privacy Policy</Trans>
              </Link>
              <a
                sx={{ color: "white", textDecoration: "none" }}
                href="#"
                className="cky-banner-element"
              >
                <Trans>Manage Cookies</Trans>
              </a>
              <Link to="https://billing.stripe.com/p/login/00gfZSadP6qb6Xe5kk">
                <Trans>Contribution Settings</Trans>
              </Link>
            </Columns>

            <div sx={{ textAlign: [null, null, "right"] }}>
              Site and identity:&nbsp;
              <Link to="https://commonknowledge.coop">Common Knowledge</Link>
              &nbsp;&amp;&nbsp;
              <Link to="http://r-b.site/">Robbie Blundell</Link>
            </div>
          </Grid>
        </ContentBlock>
      </div>
    </Fragment>
  )
}
